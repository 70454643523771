import React from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { motion } from "framer-motion";
import logo from '../../assets/logo.png';
import temptresses from '../../assets/temptressesremastered.png';
import { SocialNetworks, MintButton } from '..';
import MintSoonButton from '../mintSoonButton/MintSoonButton';
import Navbar from '../navbar/Navbar';
import MobileNavbar from '../mobileNavbar/MobileNavbar';

const TemptContent = () => {
    return (
        <div className="grow w-full flex flex-col h-full">
          <Navbar className="sticky top-0"/>
          <MobileNavbar/>
          <div className='flex flex-row my-auto sm:flex-col h-full sm:mx-auto'>
            <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }} className="my-auto sm:mx-auto sm:w-full w-[50%]">
                <h3 className="text-white text-6xl lg:text-4xl md:text-3xl xl:text-5xl lg:p-4 pt-14 pb-10 font-title text-center mt-auto">
                  TEMPTRESSES
                </h3>
                <p className="text-white text-3xl lg:text-xl md:text-lg xl:text-2xl lg:p-4 p-2 px-32 font-text text-center mb-auto">
                  Meet our four incredibly alluring Temptresses. Born from every man’s wet dream, each the very
                  epitome of beauty. Our project revolves around winning the hearts of one of these Temptresses
                  through challenges designed to test your skills in the art of seduction. Each Temptress is a one of
                  a kind NFT that plays a central role in our story narrative. “How do you get your hands on one of
                  these femme fatales?” we hear you ask. Sorry, we don’t give it up on the first date so join our
                  discord and stay tuned for exciting updates on future utility.
                </p>
            </motion.div>
            <img src={temptresses} className="w-[50%] h-auto my-auto sm:mx-auto sm:w-full" />
          </div>
          <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
            <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
          {/* <div className="flex flex-row justify-center align-middle mx-auto my-auto">
            {/* <motion.h1 initial={{ y: '-100vh' }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}  className="text-full-red text-huge md:text-8xl lg:text-9xl xl:text-9xl font-title">GOLB</motion.h1>
            {/* <motion.h2 initial={{ x: '-100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}   className="text-full-white text-8xl md:text-4xl lg:text-6xl xl:text-7xl font-title">Greedy Owls& Lucky Bunnies</motion.h2>
            <img src={logo} className="max-w-[50%] h-auto mx-auto px-32" />
            <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <h3 className="text-white text-5xl lg:text-3xl md:text-2xl xl:text-4xl lg:p-4 p-2 font-title text-center">
                ABOUT
              </h3>
              <p className="text-white text-2xl lg:text-lg md:text-lg xl:text-xl lg:p-4 p-2 px-32 font-text text-center">
                4,996 unique characters split across two tribes fighting for the affections of one lucky
                Temptress. Our story pits Simps against Chads in a narrative arc encompassing the many stages
                of courtship. Help shape our tale as you compete in both virtual &amp; IRL challenges. It will take
                wits, humour and guile to stand out from the crowd. Which tribe will walk away with the
                ultimate prize, and which will go home to Mrs. Palmer and her wrinkly daughters? Get ready to
                play your part in the GREATEST LOVE STORY EVER TOLD.
              </p>
            </motion.div>
            
            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}  transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <MintSoonButton/>
            </motion.div> 
            {/* <a href="#lore" className="text-2xl w-[15%] z-0 shrink mx-auto mt-4 my-auto align-middle justify-center animate-bounce" ><BsArrowDown className="fill-white z-0 w-[100%] h-[100%]" /></a>
            <a href="#lore" className="text-full-white shrink text-xl">Explore <b className="font-title">GOLB</b></a>
          </div> */}
        </div>
    );
  };
  
  export default TemptContent;
import { Legal } from '../containers';
import { Navbar, Footer, MobileNavbar } from '../components';

const IntroPage = () => {
    return(
        <div className="bg-white w-screen h-auto bg-no-repeat bg-cover">
            <div className='w-full fixed top-0 left-[37%] mt-8 h-auto max-w-2xl xl:max-w-xl md:max-w-md lg:max-w-lg mx-auto flex flex-col'>
                <Navbar />
                <MobileNavbar />
            </div>
            <div className='bg-temptressesbg bg-center bg-no-repeat	bg-cover h-screen w-full'>
                <div className='max-w-2xl xl:max-w-xl md:max-w-md lg:max-w-lg mx-auto flex flex-col h-full'>
                    <div className='w-full h-full flex'>
                        <div className='bg-white w-auto h-auto my-auto border-4 border-[#df8600] rounded-2xl'>
                            <h2 className='text-[#df8600] text-6xl font-text text-center p-4'>TEMPTRESSES</h2>
                            <p className='text-black font-bold text-2xl font-text p-8 pt-2'>Meet our four incredibly alluring temptresses. Born from every man’s wet dream, each the very epitome of beauty. Our project revolves around winning the hearts of one of these temptresses through challenges designed to test your skills in the art of seduction. Each temptress is a one of a kind NFT that plays a central role in our story narrative. How do you get your hands on one of these femme fatales we hear you ask? Sorry we don’t give it up on the first date so join our discord and stay tuned for exciting updates on future utility</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-simpsbg bg-no-repeat	bg-cover h-screen w-full'>
                <div className='max-w-2xl xl:max-w-xl md:max-w-md lg:max-w-lg mx-auto flex flex-col h-full'>                    
                    <div className='w-full h-full flex'>
                        <div className='bg-white w-auto h-auto my-auto border-4 border-[#df8600] rounded-2xl'>
                            <h2 className='text-[#df8600] text-6xl font-text text-center p-4'>SIMPS</h2>
                            <p className='text-black font-bold text-2xl font-text p-8 pt-2'>Meet our lovable losers, the men who will do literally anything for a slight bit of acknowledgement from a female. A tenacious fighter for Peace, Justice & Equality, our SIMPs will go above & beyond to win the hearts of their chosen temptress. From donating an entire month’s paycheck to their favourite female twitch streamer to caressing her hand and looking deep in her eyes while she makes passionate love to another man. There are no limits to what a SIMP would do to keep his Queen happy 
                            If this sounds like you then join #TEAMSIMP. Help your tribe succeed and prove once and for all that a SIMP is what a woman really wants in man 
                            A limited edition of 2498 Simps will act as your membership to our exclusive club and allow you to participate in both virtual & IRL challenges, join our discord to find out more
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-chadsbg bg-no-repeat	bg-cover h-screen w-full'>
                <div className='max-w-2xl xl:max-w-xl md:max-w-md lg:max-w-lg mx-auto flex flex-col h-full'>                    
                    <div className='w-full h-full flex'>
                        <div className='bg-white w-auto h-auto my-auto border-4 border-[#df8600] rounded-2xl'>
                            <h2 className='text-[#df8600] text-6xl font-text text-center p-4'>CHADS</h2>
                            <p className='text-black font-bold text-2xl font-text p-8 pt-2'>He possesses a body sculpted by Greek gods and hair plucked from Zeus himself. Gifted with an insatiable sex drive, what he lacks in brain power is more than made up for in sexual power.
                                Women want him, men want to be him. There is no challenge too great or a woman he couldn’t have. His tough adamantium exterior has never been breached. Could one of our Temptresses be the first to reach the gooey centre or is she destined to be another notch in the belt of conquests in this great game of love.
                                If this sounds like you then join #TEAMCHAD and roleplay with like-minded individuals. Help your tribe succeed and prove that ladies choosing CHADS is not really a choice but a mere formality
                                A limited edition of 2498 Chads will act as your membership to our exclusive club and allow you to participate in both virtual & IRL challenges, join our discord to find out more
                            </p>
                        </div>
                    </div>
                </div>
            </div>  
            
        </div>
    )};
  
  export default IntroPage;
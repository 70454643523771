import React from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { motion } from "framer-motion";
import logo from '../../assets/logo.png';
import chads from '../../assets/chads.png';
import simp1 from '../../assets/Chads/1.png';
import simp2 from '../../assets/Chads/2.png';
import simp3 from '../../assets/Chads/3.png';
import simp4 from '../../assets/Chads/4.png';
import simp5 from '../../assets/Chads/5.png';
import simp6 from '../../assets/Chads/6.png';
import { SocialNetworks, MintButton } from '..';
import MintSoonButton from '../mintSoonButton/MintSoonButton';
import Navbar from '../navbar/Navbar';
import MobileNavbar from '../mobileNavbar/MobileNavbar';

const ChadsContent = () => {
    return (
        <div className="grow w-full flex flex-col">
          <Navbar className="sticky top-0"/>
          <MobileNavbar/>
          <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <h3 className="text-white text-6xl lg:text-4xl md:text-3xl xl:text-5xl lg:p-4 pt-14 pb-10 font-title text-center">
                TEAM CHAD
              </h3>
              <p className="text-white text-3xl lg:text-xl md:text-lg xl:text-2xl lg:p-4 p-2 px-32 font-text text-center">
              He possesses a body sculpted by Greek gods and hair plucked from Zeus himself. Gifted with an
              insatiable sex drive, what he lacks in brain power is more than made up for in sexual power.
              Women want him, men want to be him. There is no challenge too great, or a woman he couldn’t
              have. His tough adamantium exterior has never been breached. Could one of our Temptresses
              be the first to reach the gooey centre, or is she destined to be another notch in the belt of
              conquests in this great game of love? <br/><br/>
              If this sounds like you then join #TEAMCHAD. Help your tribe succeed and prove that ladies
              choosing CHADS is not really a choice but a mere formality.<br/><br/>
              A limited edition of 2,498 Chads will act as your membership to our exclusive club and allow you
              to participate in both virtual &amp; IRL challenges. Join our discord to find out more.
              </p>
          </motion.div>
          <div className='w-[100vw] h-auto overflow-clip'>
            <div className='w-[400%] flex'>
              <div className='w-full'>
                <ul className='imageXScroll'>
                  <li className='w-full'><img src={simp1} /></li>
                  <li className='w-full'><img src={simp2} /></li>
                  <li className='w-full'><img src={simp3} /></li>
                  <li className='w-full'><img src={simp4} /></li>
                  <li className='w-full'><img src={simp5} /></li>
                  <li className='w-full'><img src={simp6} /></li>
                </ul>
              </div>
              <div className='w-full'>
                <ul className='imageXScroll'>
                  <li className='w-full'><img src={simp1} /></li>
                  <li className='w-full'><img src={simp2} /></li>
                  <li className='w-full'><img src={simp3} /></li>
                  <li className='w-full'><img src={simp4} /></li>
                  <li className='w-full'><img src={simp5} /></li>
                  <li className='w-full'><img src={simp6} /></li>
                </ul>
              </div>
            </div>
          </div> 
          {/* <div className="flex flex-row justify-center align-middle mx-auto my-auto">
            {/* <motion.h1 initial={{ y: '-100vh' }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}  className="text-full-red text-huge md:text-8xl lg:text-9xl xl:text-9xl font-title">GOLB</motion.h1>
            {/* <motion.h2 initial={{ x: '-100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}   className="text-full-white text-8xl md:text-4xl lg:text-6xl xl:text-7xl font-title">Greedy Owls& Lucky Bunnies</motion.h2>
            <img src={logo} className="max-w-[50%] h-auto mx-auto px-32" />
            <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <h3 className="text-white text-5xl lg:text-3xl md:text-2xl xl:text-4xl lg:p-4 p-2 font-title text-center">
                ABOUT
              </h3>
              <p className="text-white text-2xl lg:text-lg md:text-lg xl:text-xl lg:p-4 p-2 px-32 font-text text-center">
                4,996 unique characters split across two tribes fighting for the affections of one lucky
                Temptress. Our story pits Simps against Chads in a narrative arc encompassing the many stages
                of courtship. Help shape our tale as you compete in both virtual &amp; IRL challenges. It will take
                wits, humour and guile to stand out from the crowd. Which tribe will walk away with the
                ultimate prize, and which will go home to Mrs. Palmer and her wrinkly daughters? Get ready to
                play your part in the GREATEST LOVE STORY EVER TOLD.
              </p>
            </motion.div>
            
            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}  transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <MintSoonButton/>
            </motion.div> 
            {/* <a href="#lore" className="text-2xl w-[15%] z-0 shrink mx-auto mt-4 my-auto align-middle justify-center animate-bounce" ><BsArrowDown className="fill-white z-0 w-[100%] h-[100%]" /></a>
            <a href="#lore" className="text-full-white shrink text-xl">Explore <b className="font-title">GOLB</b></a>
          </div> */}
          <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
            <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
        </div>
    );
  };
  
  export default ChadsContent;
import { Header, Lore, About, Roadmap, Team, Faq } from '../containers';
import { TemptContent, Footer, MobileNavbar } from '../components';
import AboutPage from './About';

const Simps = () => {
    return(
    <div className="w-screen text-center select-none">
        <TemptContent />
    </div>
    )};
  
  export default Simps;
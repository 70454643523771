import { TeamContent, Footer, MobileNavbar } from '../components';
import AboutPage from './About';

const Team = () => {
    return(
    <div className="w-screen text-center select-none h-screen">
        <TeamContent />
    </div>
    )};
  
  export default Team;
import React, {useState, useEffect} from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { motion } from "framer-motion";
import logo from '../../assets/logo.png';
import homecharactarers from '../../assets/homecharacters.png';
import { SocialNetworks, MintButton } from '..';
import MintSoonButton from '../mintSoonButton/MintSoonButton';
import Navbar from '../navbar/Navbar';
import MobileNavbar from '../mobileNavbar/MobileNavbar';
import {ethers, providers} from "ethers";
import simp1 from '../../assets/Simps/1.png';
import simp2 from '../../assets/Chads/2.png';
import simp3 from '../../assets/Simps/3.png';
import simp4 from '../../assets/Chads/4.png';
import simp5 from '../../assets/Simps/5.png';
import simp6 from '../../assets/Chads/6.png';

const contractAddress = "0xa370c00437C55353AB38260Cc30A1b0e42e5c03b";
const ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "addresses",
				"type": "address[]"
			}
		],
		"name": "addAllowlistGeneral",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "addresses",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "numSlots",
				"type": "uint256[]"
			}
		],
		"name": "addFreemintlist",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_presaleCost",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxSupply",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxMintAmount",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_addedSupply",
				"type": "uint256"
			}
		],
		"name": "expandSupply",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mintTemptresses",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "_addresses",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_mintAmounts",
				"type": "uint256[]"
			}
		],
		"name": "ownerMint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "addresses",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "numSlots",
				"type": "uint256[]"
			}
		],
		"name": "setAllowlist",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newCost",
				"type": "uint256"
			}
		],
		"name": "setCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "setOwnersExplicit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPresale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newCost",
				"type": "uint256"
			}
		],
		"name": "setPresaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawMoney",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "allowlist",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "freemintList",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getOwnershipData",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "addr",
						"type": "address"
					},
					{
						"internalType": "uint64",
						"name": "startTimestamp",
						"type": "uint64"
					}
				],
				"internalType": "struct ERC721A.TokenOwnership",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nextOwnerToExplicitlySet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "numberMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presaleActive",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const HeaderContent = () => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isModal, setModal] = useState(false);
  const [isReceipt, setReceipt] = useState("");
  const [isTokenid, setTokenid] = useState("");
  const [numberNfts, setNfts] = useState(1);
  const [mintsLeft, setMintsleft] = useState(1);
  const [mintPrice, setMintprice] = useState(1);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = async () => {
	const {ethereum} = window;
    try{
	  setLoading(true)
      if(ethereum){
        chainID();
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, ABI, signer);
        // pop wallet to pay gas:
        let mintsleft = await contract.totalSupply();
        setMintsleft(5000-mintsleft);
        let cost = await contract.cost();
        setMintprice(cost);
        
        console.log(`MINTS: ${mintsleft}`);
		console.log(`COST: ${cost}`);
        setLoading(false)
      }
	  setLoading(false)
	  setIsOpen(true);
      document.body.style.overflowY = 'hidden';
    } catch(e){
	  closeModal();
      setLoading(false)
      console.log(e)
    }
  }

  function handleChangeNFTs(event) {
    setNfts(event.target.value);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflowY = 'unset';
  }
  
 
  // Is the wallet connected? metamask injects the "ethereum" object
  const walletConnected = async () =>{

    const {ethereum} = window;
    if(!ethereum){
      console.log("Please download metamask");
      return;
    
    } else{
      console.log("Ethereum object found", ethereum);
    }

    // Get chain ID, trhow alert if not connected to Rinkeby
    chainID();

    //If site already connnected to metamask, get user public key
    const accounts = await ethereum.request({method: 'eth_accounts'})

    if(accounts.length !== 0){
      const account = accounts[0];
      console.log(`User account: ${account}`);

      // Setup listener! This is for the case where a user comes to our site
      // and ALREADY had their wallet connected + authorized.
      eventListener();

    } else {
      console.log("Site is not authorized to access metamask")
    }

  }


// Function to connect site to the metamask wallet
  const connectWallet = async () => {
    const {ethereum} = window;
    try{
      if(!ethereum){
        alert("Get metamask!");
        return;
      }
      // Connect to metamask wallet
      const accounts = await ethereum.request({method: "eth_requestAccounts"});
      console.log(`connected: ${accounts[0]}`)

      setCurrentAccount(accounts[0]);

      eventListener();

      openModal();

    } catch (e){
      console.log(e)
    }
  }


  // function that listens for smart contract events
  const eventListener = async () => {

    const {ethereum} = window;
    try{
      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, ABI, signer);

        // webhook, listening for smart contract events
        const event_name = "nftMinted";
        contract.on(event_name, (from, tokenId) => {
          console.log(`From: ${from} , TokenID: ${tokenId}`)

          var id = tokenId.toNumber();
          setTokenid(id)
          // alert(`It can take a max of 10 min to show up on OpenSea. Here's the link: https://testnets.opensea.io/assets/${contractAddress}/${tokenId.toNumber()}`)
          
        })

      }
      console.log("Setup event listener!")

    } catch(e){
      console.log(e);
    }
  }


  // Get user's current chain ID 
  const chainID = async () => {

    const {ethereum} = window;
    let chainId = await ethereum.request({ method: 'eth_chainId' });
    console.log("Connected to chain " + chainId);
    // String, hex code of the chainId of the Rinkebey test network
    const rinkebyChainId = "0x61"; 
    if (chainId !== rinkebyChainId) {
      //alert("You are not connected to the Rinkeby Test Network!");
    }
  }


  // Mint an NFT and get tx hash
  const mintNFT = async () => {

    const {ethereum} = window;
    try{
      if(ethereum){
        chainID();
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, ABI, signer);

        // pop wallet to pay gas:
        let tx = await contract.mint(numberNfts, { value: mintPrice * numberNfts });
        setLoading(true)
        
        await tx.wait();
        
        console.log(`Transaction mined at https://rinkeby.etherscan.io/tx/${tx.hash}`);
        const hash = tx.hash;

        setReceipt(hash)
        setLoading(false)
      
      }

      setLoading(false)

    } catch(e){
      setLoading(false)
      console.log(e)
    }
  }

    // Render Methods
    const renderModal = () => (
      <div className="w-screen h-screen bg-[#00000099] z-30 absolute top-0 left-0">
        <div className="centered-axis-xy modal w-[40%] h-[50%] flex flex-col z-50 md:w-[90%] md:h-[90%]" >
          <div className="modalHeader flex flex-row justify-between">
            <h5 className="heading md:text-2xl font-title text-4xl align-middle self-center my-auto mx-auto">Mint your NFT</h5>
            <button className="closeBtn mx-auto" onClick={closeModal} >Close</button>
          </div>
          <div className='flex flex-row w-full h-full'>
            <div className='flex flex-col w-[50%] h-full'>
              <div className="flex flex-row align-middle items-center md:flex-col h-full">
                <div className="flex flex-col w-full align-middle my-auto">
                  <div className="flex flex-row justify-between md:pt-0 p-6 pt-10">
                    <h5 className="justify-start font-text text-xl">Mints left: </h5>
                    <p className="justify-end font-text text-xl">{mintsLeft}/4996</p>
                  </div>
                  <div className="flex flex-row justify-between md:pt-0 p-6 pt-10">
                    <h5 className="justify-start font-text text-xl">Mint price: </h5>
                    <p className="justify-end font-text text-xl">{ethers.utils.formatEther(mintPrice)} ETH</p>
                  </div>
                  <div className="flex flex-row justify-between md:pt-0 p-6 pt-10">
                    <h5 className="justify-start font-text text-xl">Number of NFTs: </h5>
                    <p className="justify-end font-text text-xl">{numberNfts}</p>
                  </div>
                  <input type="range" className="mx-auto slider bg-black mb-16 m-4 7 w-[90%]" min="1" max="20" value={numberNfts} onChange={handleChangeNFTs} />
                  <button type="button" onClick={mintNFT} className="w-[90%] bg-[#ffffff] text-black mx-auto rounded-lg p-4 font-title text-xl">Mint NFTs</button>
                </div>
              </div>
            </div>
            <div className='w-[50%] h-[90%] px-8 overflow-clip flex flex-col'>
                <div className='h-full mb-2'>
                  <ul className='imageYScroll'>
                    <li className='w-full mb-2'><img src={simp1} /></li>
                    <li className='w-full mb-2'><img src={simp2} /></li>
                    <li className='w-full mb-2'><img src={simp3} /></li>
                    <li className='w-full mb-2'><img src={simp4} /></li>
                    <li className='w-full mb-2'><img src={simp5} /></li>
                    <li className='w-full mb-2'><img src={simp6} /></li>
                  </ul>
                  <ul className='imageYScroll'>
                    <li className='w-full mb-2'><img src={simp1} /></li>
                    <li className='w-full mb-2'><img src={simp2} /></li>
                    <li className='w-full mb-2'><img src={simp3} /></li>
                    <li className='w-full mb-2'><img src={simp4} /></li>
                    <li className='w-full mb-2'><img src={simp5} /></li>
                    <li className='w-full mb-2'><img src={simp6} /></li>
                  </ul>
                </div>
                {/* <div className='h-full mb-2'>
                  <ul className='imageYScroll'>
                    <li className='w-full mb-2'><img src={simp1} /></li>
                    <li className='w-full mb-2'><img src={simp2} /></li>
                    <li className='w-full mb-2'><img src={simp3} /></li>
                    <li className='w-full mb-2'><img src={simp4} /></li>
                    <li className='w-full mb-2'><img src={simp5} /></li>
                    <li className='w-full mb-2'><img src={simp6} /></li>
                  </ul>
                </div> */}
              </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className='w-screen h-screen'>
        {modalIsOpen ? renderModal() : <></>}
        <div className="grow w-full h-screen sm:overflow-scroll flex flex-col">
          <Navbar className="sticky top-0"/>
          <MobileNavbar />
          <img src={logo} className="max-w-[35%] sm:w-[80%] sm:max-w-full h-auto mx-auto mt-8 pt-0" />
          <div className="flex flex-row justify-center align-middle mx-auto my-auto sm:flex-col-reverse">
            {/* <motion.h1 initial={{ y: '-100vh' }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}  className="text-full-red text-huge md:text-8xl lg:text-9xl xl:text-9xl font-title">GOLB</motion.h1> */}
            {/* <motion.h2 initial={{ x: '-100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}   className="text-full-white text-8xl md:text-4xl lg:text-6xl xl:text-7xl font-title">Greedy Owls& Lucky Bunnies</motion.h2> */}
            <img src={homecharactarers} className="w-[50%] h-auto mx-auto sm:w-[80%] sm:max-w-full" />
            <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }} className="my-auto">
              <h3 className="text-white text-5xl lg:text-3xl md:text-2xl xl:text-4xl lg:p-4 p-2 font-title text-center">
                ABOUT
              </h3>
              <p className="text-white text-2xl lg:text-lg md:text-lg xl:text-xl lg:p-4 p-2 px-32 font-text text-center">
                4,996 unique characters split across two teams fighting for the affections of one lucky
                Temptress. Our story pits Simps against Chads in a narrative arc encompassing the many stages
                of courtship. Help shape our tale as you compete in both virtual &amp; IRL challenges. It will take
                wits, humour and guile to stand out from the crowd. Which team will walk away with the
                ultimate prize, and which will go home to Mrs. Palmer and her wrinkly daughters? Get ready to
                play your part in the GREATEST LOVE STORY EVER TOLD.
              </p>
              {/* <div className="flex justify-between xl:py-3 px-2 py-6 flex-wrap w-[100%]">
                <button type="button" onClick={connectWallet} className="w-[80%] mx-auto bg-white text-black rounded-lg p-3 font-title text-xl"> Mint SVC </button>
              </div> */}
            </motion.div>
            
            {/* <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}  transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <MintSoonButton/>
            </motion.div> */}
            
            {/* <a href="#lore" className="text-2xl w-[15%] z-0 shrink mx-auto mt-4 my-auto align-middle justify-center animate-bounce" ><BsArrowDown className="fill-white z-0 w-[100%] h-[100%]" /></a>
            <a href="#lore" className="text-full-white shrink text-xl">Explore <b className="font-title">GOLB</b></a> */}
          </div>
          <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
            <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
        </div>
      </div>
    );
  };
  
  export default HeaderContent;
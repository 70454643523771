import React from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { motion } from "framer-motion";
import logo from '../../assets/logo.png';
import { SocialNetworks, MintButton } from '..';
import MintSoonButton from '../mintSoonButton/MintSoonButton';
import Navbar from '../navbar/Navbar';
import MobileNavbar from '../mobileNavbar/MobileNavbar';

const FundContent = () => {
    return (
        <div className="grow w-full flex flex-col">
          <Navbar className="sticky top-0"/>
          <MobileNavbar />
          <div className='flex flex-col'>
            <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }} className="w-full">
                <h3 className="text-white text-6xl lg:text-4xl md:text-3xl xl:text-5xl lg:p-4 pt-14 pb-10 font-title text-center">
                  COMMUNITY FUND
                </h3>
                <p className="text-white text-3xl lg:text-xl md:text-lg xl:text-2xl lg:p-4 p-2 px-32 font-text text-center">
                  What sets us apart from other projects is our commitment to you, our members to keep the fun
                  and good vibes rolling. The NFT space is saturated and lots of projects die after the initial hype
                  cycle as overall substance is lacking. <br/>
                  We aim to set ourselves apart from the crowd by giving a big chunk of our mint proceeds +
                  ongoing royalties back to you in the form of our community chest.<br/>
                  Our team commits to funding our chest with 40% of mint proceeds + 50% of ongoing royalties.
                  Our community chest will be used to fund prizepools for our virtual challenges as well as fund
                  our IRL events.<br/>
                  We as a project win if our members win and by giving back to you, we believe we have the right
                  ingredients in place to reward you for your engagement
                </p>
            </motion.div>
            <img src={logo} className="relative w-[80%] mx-auto" />
          </div>
          <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
          <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
          {/* <div className="flex flex-row justify-center align-middle mx-auto my-auto">
            {/* <motion.h1 initial={{ y: '-100vh' }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}  className="text-full-red text-huge md:text-8xl lg:text-9xl xl:text-9xl font-title">GOLB</motion.h1>
            {/* <motion.h2 initial={{ x: '-100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}   className="text-full-white text-8xl md:text-4xl lg:text-6xl xl:text-7xl font-title">Greedy Owls& Lucky Bunnies</motion.h2>
            <img src={logo} className="max-w-[50%] h-auto mx-auto px-32" />
            <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <h3 className="text-white text-5xl lg:text-3xl md:text-2xl xl:text-4xl lg:p-4 p-2 font-title text-center">
                ABOUT
              </h3>
              <p className="text-white text-2xl lg:text-lg md:text-lg xl:text-xl lg:p-4 p-2 px-32 font-text text-center">
                4,996 unique characters split across two tribes fighting for the affections of one lucky
                Temptress. Our story pits Simps against Chads in a narrative arc encompassing the many stages
                of courtship. Help shape our tale as you compete in both virtual &amp; IRL challenges. It will take
                wits, humour and guile to stand out from the crowd. Which tribe will walk away with the
                ultimate prize, and which will go home to Mrs. Palmer and her wrinkly daughters? Get ready to
                play your part in the GREATEST LOVE STORY EVER TOLD.
              </p>
            </motion.div>
            
            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}  transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <MintSoonButton/>
            </motion.div> 
            {/* <a href="#lore" className="text-2xl w-[15%] z-0 shrink mx-auto mt-4 my-auto align-middle justify-center animate-bounce" ><BsArrowDown className="fill-white z-0 w-[100%] h-[100%]" /></a>
            <a href="#lore" className="text-full-white shrink text-xl">Explore <b className="font-title">GOLB</b></a>
          </div> */}
        </div>
    );
  };
  
  export default FundContent;
import React, { useState } from 'react';

const Navbar = () => {
    return (
      <section id="navbar-container navbar-sticky" className="navbar-div sm:hidden z-20 w-[100%] pb-6">
          <nav className="h-full border-gray-200 dark:bg-gray-800 rounded-lg mt-8">
            <ul className="flex flex-row mt-0 text-white text-sm font-semibold mx-auto w-full h-full align-middle text-center">
              <li className="rounded-l-lg font-text h-full w-full lg:text-sm text-xl ">
                <a href="/" type="button" className="h-full hover:text-[#ffffff] text-center w-full">Home</a>
              </li>
              <li className="font-text h-full w-full lg:text-sm text-xl">
                <a href="/simps" type="button" className="h-full  align-middle hover:text-[#ffffff] w-full">Simps</a>             
              </li>
              <li className="font-text h-full w-full lg:text-sm text-xl">
                <a href="/chads" type="button" className="h-full  hover:text-[#ffffff] w-full">Chads</a>
              </li>
              <li className=" font-text h-full w-full lg:text-sm text-xl">
                <a href="/temptresses" type="button" className=" hover:text-[#ffffff] h-full w-full">Temptress</a>               
              </li>
              <li className="font-text h-full w-full lg:text-sm text-xl">
                <a href="/wanted-simps" type="button" className="h-full  hover:text-[#ffffff] w-full">Most Wanted Simps</a>                
              </li>
              <li className="font-text h-full w-full lg:text-sm text-xl">
                <a href="/wanted-chads" type="button" className=" hover:text-[#ffffff] h-full w-full">Most Wanted Chads</a>               
              </li>
              <li className="font-text h-full w-full lg:text-sm text-xl ">
                <a href="/roadmap" type="button" className=" hover:text-[#ffffff] h-full w-full">Roadmap</a>               
              </li>
              <li className="font-text h-full w-full lg:text-sm text-xl ">
                <a href="/fund" type="button" className=" hover:text-[#ffffff] h-full w-full">Community Fund</a>               
              </li>
              <li className="font-text h-full w-full lg:text-sm text-xl ">
                <a href="/team" type="button" className=" hover:text-[#ffffff] h-full w-full">Team</a>               
              </li>
              <li className="font-text h-full w-full lg:text-sm text-xl ">
                <a href="/faq" type="button" className=" hover:text-[#ffffff] h-full w-full">Faq</a>               
              </li>
            </ul>
          </nav>
        </section>  
    );
  };
  
  export default Navbar;
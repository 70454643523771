import React from 'react';
import { BsArrowDown } from 'react-icons/bs';

const HeaderContent = () => {
    return (
        <div className="grow w-full bg-dark-bg flex flex-row">
          <div className="max-w-2xl justify-center p-4 align-middle mx-auto my-auto">
            <div className='flex flex-row w-full'>
              <a href="/legal" className="text-white font-text">Terms & Conditions</a>
              <a href="/legal" className="text-white font-text">Terms & Conditions</a>
              <a href="/legal" className="text-white font-text">Terms & Conditions</a>
              <a href="/legal" className="text-white font-text">Terms & Conditions</a>
              <a href="/legal" className="text-white font-text">Terms & Conditions</a>
              <a href="/legal" className="text-white font-text">Terms & Conditions</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
        </div>
    );
  };
  
  export default HeaderContent;
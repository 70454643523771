import { Header, Lore, About, Roadmap, Team, Faq } from '../containers';
import { FundContent, Footer, MobileNavbar } from '../components';
import AboutPage from './About';

const FaqPage = () => {
    return(
    <div className="w-screen text-center select-none h-screen">
        <Faq />
    </div>
    )};
  
  export default FaqPage;
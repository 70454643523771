import React from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { motion } from "framer-motion";
import logo from '../../assets/chadslogo.png';
import chads from '../../assets/wantedchads.png';
import { SocialNetworks, MintButton } from '..';
import MintSoonButton from '../mintSoonButton/MintSoonButton';
import Navbar from '../navbar/Navbar';
import simp1 from '../../assets/WantedChads/1.png';
import simp2 from '../../assets/WantedChads/2.png';
import simp3 from '../../assets/WantedChads/3.png';
import simp4 from '../../assets/WantedChads/4.png';
import simp5 from '../../assets/WantedChads/5.png';
import simp6 from '../../assets/WantedChads/6.png';
import simp7 from '../../assets/WantedChads/7.png';
import simp8 from '../../assets/WantedChads/8.png';
import simp9 from '../../assets/WantedChads/9.png';
import simp10 from '../../assets/WantedChads/10.png';
import simp11 from '../../assets/WantedChads/11.png';
import simp12 from '../../assets/WantedChads/12.png';
import simp13 from '../../assets/WantedChads/13.png';
import simp14 from '../../assets/WantedChads/14.png';
import simp15 from '../../assets/WantedChads/15.png';
import simp16 from '../../assets/WantedChads/16.png';
import simp17 from '../../assets/WantedChads/17.png';
import simp18 from '../../assets/WantedChads/18.png';
import MobileNavbar from '../mobileNavbar/MobileNavbar';

const MostChadsContent = () => {
    return (
        <div className="grow w-full flex flex-col h-full">
          <Navbar className="sticky top-0"/>
          <MobileNavbar/>
          <div className='flex flex-row-reverse my-auto h-full sm:flex-col'>
            <motion.div className="w-[50%] flex flex-col sm:w-full">
                <img src={logo} className="w-[25%] h-[auto] mx-auto" />
                <h3 className="text-white text-6xl lg:text-4xl md:text-3xl xl:text-5xl lg:p-4 pt-14 pb-10 font-title text-center">
                  METAVERSE MOST WANTED
                </h3>
                <p className="text-white text-3xl lg:text-xl md:text-lg xl:text-2xl lg:p-4 p-2 px-32 font-text text-center">
                  The pride of our collection, 20 special golden edition characters covering the entire spectrum of
                  Chad stereotypes. These are the cream of the crop and will be highly coveted collectors’ items
                </p>
            </motion.div>
            <div className='w-[50%] h-[100vh] sm:w-full flex flex-row overflow-hidden'>
              <div className='h-[100vh]'>
                <div className='relative top-0 left-0 imageYScroll'>
                  <img src={simp1} />
                  <img src={simp2} />
                  <img src={simp3} />
                  <img src={simp4} />
                  <img src={simp5} />
                  <img src={simp6} />
                </div>
                <div className='relative top-0 left-0 mt-[100vh] imageYScroll'>
                  <img src={simp1} />
                  <img src={simp2} />
                  <img src={simp3} />
                  <img src={simp4} />
                  <img src={simp5} />
                  <img src={simp6} />
                </div>
              </div>
              <div className='h-[100vh]'>
                <div className='relative top-0 left-0 mt-[100vh] imageYScrollR'>
                  <img src={simp7} />
                  <img src={simp8} />
                  <img src={simp9} />
                  <img src={simp10} />
                  <img src={simp11} />
                  <img src={simp12} />
                </div>
                <div className='relative top-0 left-0  imageYScrollR'>
                  <img src={simp7} />
                  <img src={simp8} />
                  <img src={simp9} />
                  <img src={simp10} />
                  <img src={simp11} />
                  <img src={simp12} />
                </div>
              </div>
              <div className='h-[100vh]'>
                <div className='relative top-0 left-0 imageYScroll'>
                  <img src={simp13} />
                  <img src={simp14} />
                  <img src={simp15} />
                  <img src={simp16} />
                  <img src={simp17} />
                  <img src={simp18} />
                </div>
                <div className='relative top-0 left-0 mt-[100vh] imageYScroll'>
                  <img src={simp13} />
                  <img src={simp14} />
                  <img src={simp15} />
                  <img src={simp16} />
                  <img src={simp17} />
                  <img src={simp18} />
                </div>
              </div>
            </div>
            {/* <div className='w-[50%] h-[100vh] sm:w-full overflow-hidden flex flex-row'>
              <div className='h-[100%] flex flex-col'>
                <div className='h-full'>
                  <ul className='imageYScroll'>
                    <li className='w-full'><img src={simp1} /></li>
                    <li className='w-full'><img src={simp2} /></li>
                    <li className='w-full'><img src={simp3} /></li>
                    <li className='w-full'><img src={simp4} /></li>
                    <li className='w-full'><img src={simp5} /></li>
                    <li className='w-full'><img src={simp6} /></li>
                  </ul>
                </div>
                <div className='h-full '>
                  <ul className='imageYScroll'>
                    <li className='w-full'><img src={simp1} /></li>
                    <li className='w-full'><img src={simp2} /></li>
                    <li className='w-full'><img src={simp3} /></li>
                    <li className='w-full'><img src={simp4} /></li>
                    <li className='w-full'><img src={simp5} /></li>
                    <li className='w-full'><img src={simp6} /></li>
                  </ul>
                </div>
              </div>
              <div className='h-[100%] flex flex-col'>
                <div className='h-full'>
                  <ul className='imageYScrollR'>
                    <li className='w-full'><img src={simp7} /></li>
                    <li className='w-full'><img src={simp8} /></li>
                    <li className='w-full'><img src={simp9} /></li>
                    <li className='w-full'><img src={simp10} /></li>
                    <li className='w-full'><img src={simp11} /></li>
                    <li className='w-full'><img src={simp12} /></li>
                  </ul>
                </div>
                <div className='h-full '>
                  <ul className='imageYScrollR'>
                    <li className='w-full'><img src={simp7} /></li>
                    <li className='w-full'><img src={simp8} /></li>
                    <li className='w-full'><img src={simp9} /></li>
                    <li className='w-full'><img src={simp10} /></li>
                    <li className='w-full'><img src={simp11} /></li>
                    <li className='w-full'><img src={simp12} /></li>
                  </ul>
                </div>
              </div>
              <div className='h-[100%] flex flex-col'>
                <div className='h-full'>
                  <ul className='imageYScroll'>
                    <li className='w-full'><img src={simp13} /></li>
                    <li className='w-full'><img src={simp14} /></li>
                    <li className='w-full'><img src={simp15} /></li>
                    <li className='w-full'><img src={simp16} /></li>
                    <li className='w-full'><img src={simp17} /></li>
                    <li className='w-full'><img src={simp18} /></li>
                  </ul>
                </div>
                <div className='h-full '>
                  <ul className='imageYScroll'>
                  <li className='w-full'><img src={simp13} /></li>
                    <li className='w-full'><img src={simp14} /></li>
                    <li className='w-full'><img src={simp15} /></li>
                    <li className='w-full'><img src={simp16} /></li>
                    <li className='w-full'><img src={simp17} /></li>
                    <li className='w-full'><img src={simp18} /></li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
          <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
            <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
        </div>
    );
  };
  
  export default MostChadsContent;
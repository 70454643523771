import {React, useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion";

const LegalContent = ({title, text}) => {
    const [alredyAnimated, setAlredyAnimated] = useState();
    const {ref, inView} = useInView();
    const animationRight = useAnimation();
    const animationLeft = useAnimation();

    useEffect(() => {
      if(inView){
          animationRight.start({
            x:0,
            transition:{
              type: "spring", 
              stiffness: 25, 
              duration: 2 
            },
            initial:{
              x:'100vh'
            }
          })
          animationLeft.start({
            x:0,
            transition:{
              type: "spring", 
              stiffness: 25, 
              duration: 2 
            },
            initial:{
              x:'-100vh'
            }
          })
          setAlredyAnimated(true);
      }
      if(!inView&&!alredyAnimated){
        animationRight.start({
          x:'100vh',
        })
        animationLeft.start({
          x:'-100vh',
        })
      }
      if(!inView&&alredyAnimated){
        animationRight.start({
          x:0,
        })
        animationLeft.start({
          x:0,
        })
      }
    })

    return (
        <div ref={ref} className="grow flex lg:text-center text-justify lg:w-[75%] xl:w-2/3 w-2/3 mx-auto my-auto pt-6 h-fit">
          <div className="lg:max-w-[95%] h-fit lg:p-0 p-8">
            <motion.h4 animate={animationLeft} className="lg:text-4xl text-white px-2 font-title text-4xl">{title}</motion.h4>
            <motion.p animate={animationRight} className="text-full-white lg:text-2xl font-text text-2xl">{text}</motion.p>
          </div>
        </div>
      
    );
  };
  
  export default LegalContent;
import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

const MobileNavbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
    return (
      <div className="hidden md:flex text-full-white flex-col bg-black sticky z-40 top-0 font-text p-4 md:text-3xl w-full text-5xl">
          <div className="flex flex-row">
            <div className="ml-auto flex-col mr-4 justify-end my-auto items-center">
              {toggleMenu
                ? <RiCloseLine color="#fff" className="text-3xl" size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color="#fff" className="text-3xl" size={27} onClick={() => setToggleMenu(true)} />}
            </div>
          </div>
          {toggleMenu && (
            <div className="flex h-full w-full justify-center items-center flex-col sticky right-0 top-0 mt-4 mx-auto rounded-lg">
              <div>
                <p><a href="/">Home</a></p>
                <p><a href="/simps">Simps</a></p>
                <p><a href="/chads">Chads</a></p>
                <p><a href="/temptresses">Temptress</a></p>
                <p><a href="/wanted-simps">Most Wanted Simps</a></p>
                <p><a href="/wanted-chads">Most Wanted Chads</a></p>
                <p><a href="/roadmap">Roadmap</a></p>
                <p><a href="/fund">Community Fund</a></p>
                <p><a href="/team">Team</a></p>
                <p><a href="/faq">Faq</a></p>
              </div>
            </div>
            )}
        </div> 
    );
  };
  
  export default MobileNavbar;
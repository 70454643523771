import React from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { motion } from "framer-motion";
import logo from '../../assets/logo.png';
import uncleRico from '../../assets/TEAM - UNCLE RICO.png';
import CUCKBOI from '../../assets/TEAM - CUCKBOI.png';
import { SocialNetworks, MintButton } from '..';
import MintSoonButton from '../mintSoonButton/MintSoonButton';
import Navbar from '../navbar/Navbar';
import MobileNavbar from '../mobileNavbar/MobileNavbar';

const TeamContent = () => {
    return (
        <div className="w-full flex flex-col h-full">
          <Navbar className="sticky top-0"/>
          <MobileNavbar/>
          <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <h3 className="text-white text-6xl lg:text-4xl md:text-3xl xl:text-5xl lg:p-4 pt-14 pb-10 font-title text-center">
                TEAM
              </h3>
              <p className="text-white text-3xl lg:text-xl md:text-lg xl:text-2xl lg:p-4 p-2 px-32 font-text text-center">
                We are not former employees of Google, Meta or any other web2 entity. We are not influencers
                or web3 whales. We are simple degens who embarked on our NFT journey back in Jan 2021 and
                haven’t looked back. This is our first NFT project, as such we have developed a conservative,
                realistic and easily achievable roadmap. No promise of a game, DAO, collabs or token – those
                things are disingenuous to promise right off the bat, let us hone our skills first then we can
                promise the world. <br/><br/>
                If an experienced team is what you are after then unfortunately, we do not fit the criteria but we
                hope you join our discord, follow us on twitter, engage with our community and give us the
                chance to earn your trust
              </p>
          </motion.div>
          <div className='flex flex-row sm:flex-col max-h-3xl pt-12'>
            <div className="w-[33%] h-[33%] ml-auto sm:mx-auto sm:w-[80%] flex flex-col">
              <img src={uncleRico} />
              <p className='font-text text-white text-3xl p-6'>UNCLE RICO</p>
            </div>
            <div className="w-[33%] flex h-auto mr-auto sm:mx-auto sm:w-[80%] sm:mt-32 flex-col">
              <img src={CUCKBOI} />
              <p className='font-text text-white text-3xl p-6'>CUCKBOI</p>
            </div>
          </div>
          <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
          <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
        </div>
    );
  };
  
  export default TeamContent;
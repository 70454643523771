import './App.css';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import LegalPage from "./pages/LegalPage";
import SimpsPage from "./pages/Simps";
import ChadsPage from "./pages/Chads";
import TemptPage from "./pages/Tempt";
import FundPage from "./pages/Fund";
import TeamPage from "./pages/Team";
import RoadmapPage from "./pages/RoadmapPage";
import FaqPage from "./pages/FaqPage";
import WantedChadsPage from "./pages/MostWantedChads";
import WantedSimpsPage from "./pages/MostWantedSimps";
import IntroPage from "./pages/Introduction";
import NoPage from "./pages/NoPage";
import WantedPage from './pages/Wanted';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, {useEffect } from 'react';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-3CL0KXV1R2";
ReactGA.initialize(TRACKING_ID);

const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="legal" element={<LegalPage />} />
          <Route path="simps" element={<SimpsPage />} />
          <Route path="fund" element={<FundPage />} />
          <Route path="chads" element={<ChadsPage />} />
          <Route path="temptresses" element={<TemptPage />} />
          <Route path="wanted-chads" element={<WantedChadsPage />} />
          <Route path="wanted-simps" element={<WantedSimpsPage />} />
          <Route path="introduction" element={<IntroPage />} />
          <Route path="roadmap" element={<RoadmapPage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="mostwanted" element={<WantedPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { motion, useTime, useTransform } from "framer-motion";
import logo from '../../assets/logo.png';
import simp1 from '../../assets/Simps/1.png';
import simp2 from '../../assets/Simps/2.png';
import simp3 from '../../assets/Simps/3.png';
import simp4 from '../../assets/Simps/4.png';
import simp5 from '../../assets/Simps/5.png';
import simp6 from '../../assets/Simps/6.png';
import { SocialNetworks, MintButton } from '..';
import MintSoonButton from '../mintSoonButton/MintSoonButton';
import Navbar from '../navbar/Navbar';
import MobileNavbar from '../mobileNavbar/MobileNavbar';

const SimpsContent = () => {
  const time = useTime();
  const rotate = useTransform(time, [0, 4000], [0, 360], { clamp: false });

    return (
        <div className="grow w-full flex h-auto flex-col">
          <Navbar className="sticky top-0"/>
          <MobileNavbar/>
          <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <h3 className="text-white text-6xl lg:text-4xl md:text-3xl xl:text-5xl lg:p-4 pt-14 pb-10 font-title text-center">
                TEAM SIMP
              </h3>
              <p className="text-white text-3xl lg:text-xl md:text-lg xl:text-2xl lg:p-4 p-2 px-32 font-text text-center">
                Meet our lovable losers, the men who will do literally anything for a bit of acknowledgement
                from a female. A tenacious fighter for Peace, Justice &amp; Equality, our Simps will go above &amp;
                beyond to win the hearts of their chosen Temptress. From donating an entire month’s paycheck
                to their favourite female twitch streamer, to caressing her hand and looking deep in her eyes
                while she makes passionate love to another man. There are no limits to what a SIMP would do
                to keep his Queen happy. <br/>
                If this sounds like you then join #TEAMSIMP. Help your tribe succeed and prove once and for all
                that a SIMP is what a woman really wants in a man.<br/>
                A limited edition of 2,498 Simps will act as your membership to our exclusive club and allow you
                to participate in both virtual &amp; IRL challenges. Join our discord to find out more.
              </p>
          </motion.div>
          <div className='w-[100vw] h-auto overflow-clip'>
            <div className='w-[400%] flex'>
              <div className='w-full'>
                <ul className='imageXScroll'>
                  <li className='w-full'><img src={simp1} /></li>
                  <li className='w-full'><img src={simp2} /></li>
                  <li className='w-full'><img src={simp3} /></li>
                  <li className='w-full'><img src={simp4} /></li>
                  <li className='w-full'><img src={simp5} /></li>
                  <li className='w-full'><img src={simp6} /></li>
                </ul>
              </div>
              <div className='w-full'>
                <ul className='imageXScroll'>
                  <li className='w-full'><img src={simp1} /></li>
                  <li className='w-full'><img src={simp2} /></li>
                  <li className='w-full'><img src={simp3} /></li>
                  <li className='w-full'><img src={simp4} /></li>
                  <li className='w-full'><img src={simp5} /></li>
                  <li className='w-full'><img src={simp6} /></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
            <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
          {/* <div className="flex flex-row justify-center align-middle mx-auto my-auto">
            {/* <motion.h1 initial={{ y: '-100vh' }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}  className="text-full-red text-huge md:text-8xl lg:text-9xl xl:text-9xl font-title">GOLB</motion.h1>
            {/* <motion.h2 initial={{ x: '-100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}   className="text-full-white text-8xl md:text-4xl lg:text-6xl xl:text-7xl font-title">Greedy Owls& Lucky Bunnies</motion.h2>
            <img src={logo} className="max-w-[50%] h-auto mx-auto px-32" />
            <motion.div initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <h3 className="text-white text-5xl lg:text-3xl md:text-2xl xl:text-4xl lg:p-4 p-2 font-title text-center">
                ABOUT
              </h3>
              <p className="text-white text-2xl lg:text-lg md:text-lg xl:text-xl lg:p-4 p-2 px-32 font-text text-center">
                4,996 unique characters split across two tribes fighting for the affections of one lucky
                Temptress. Our story pits Simps against Chads in a narrative arc encompassing the many stages
                of courtship. Help shape our tale as you compete in both virtual &amp; IRL challenges. It will take
                wits, humour and guile to stand out from the crowd. Which tribe will walk away with the
                ultimate prize, and which will go home to Mrs. Palmer and her wrinkly daughters? Get ready to
                play your part in the GREATEST LOVE STORY EVER TOLD.
              </p>
            </motion.div>
            
            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}  transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              <MintSoonButton/>
            </motion.div> 
            {/* <a href="#lore" className="text-2xl w-[15%] z-0 shrink mx-auto mt-4 my-auto align-middle justify-center animate-bounce" ><BsArrowDown className="fill-white z-0 w-[100%] h-[100%]" /></a>
            <a href="#lore" className="text-full-white shrink text-xl">Explore <b className="font-title">GOLB</b></a>
          </div> */}
        </div>
    );
  };
  
  export default SimpsContent;
import {React, useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import { Navbar } from '../../components';
import { motion, useAnimation } from "framer-motion";
import {MobileNavbar} from '../../components';

const Roadmap = () => {
  const [alredyAnimated, setAlredyAnimated] = useState();
  const {ref, inView} = useInView();
  const animationRight = useAnimation();
  const animationLeft = useAnimation();

  useEffect(() => {
    if(inView){
        animationRight.start({
          x:0,
          transition:{
            type: "spring", 
            stiffness: 25, 
            duration: 2 
          },
          initial:{
            x:'100vh'
          }
        })
        animationLeft.start({
          x:0,
          transition:{
            type: "spring", 
            stiffness: 25, 
            duration: 2 
          },
          initial:{
            x:'-100vh'
          }
        })
        setAlredyAnimated(true);
    }
    if(!inView&&!alredyAnimated){
      animationRight.start({
        x:'100vh',
      })
      animationLeft.start({
        x:'-100vh',
      })
    }
    if(!inView&&alredyAnimated){
      animationRight.start({
        x:0,
      })
      animationLeft.start({
        x:0,
      })
    }
  })
    return (
      <div className="grow w-full flex flex-col h-full">
          <Navbar className="sticky top-0"/>
          <MobileNavbar/>
          <div ref={ref} className="w-full rounded-lg py-24 p-8 grid grid-cols-21 pt-36 grid-rows-7">
            <div className="row-span-full md:col-start-3 md:col-span-2 col-start-11 flex mx-auto flex-wrap">
              <div className="m-2 w-[8px] rounded-3xl h-full self-center bg-full-white" />
            </div>
            <div className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-1 mx-auto w-[60px] h-[60px] bg-blue-600 text-white font-title text-3xl rounded-full pt-2">1</div>
            <div className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-2 mx-auto w-[60px] h-[60px] bg-red-600 text-white font-title text-3xl rounded-full pt-2">2</div>
            <div className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-3 mx-auto w-[60px] h-[60px] bg-blue-600 text-white font-title text-3xl rounded-full pt-2">3</div>
            <div className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-4 mx-auto w-[60px] h-[60px] bg-red-600 text-white font-title text-3xl rounded-full pt-2">4</div>
            <div className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-5 mx-auto w-[60px] h-[60px] bg-blue-600 text-white font-title text-3xl rounded-full pt-2">5</div>
            <div className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-6 mx-auto w-[60px] h-[60px] bg-red-600 text-white font-title text-3xl rounded-full pt-2">6</div>
            <div className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-7 mx-auto w-[60px] h-[60px] bg-blue-600 text-white font-title text-3xl rounded-full pt-2">7</div>
            {/* <img src={P1R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-1 mx-auto w-2/3"/> */}
            {/* <img src={P2R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-2 mx-auto w-2/3"/> */}
            {/* <img src={P3R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-3 mx-auto w-2/3"/> */}
            {/* <img src={P4R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-4 mx-auto w-2/3"/> */}
            {/* <img src={P5R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-5 mx-auto w-2/3"/> */}
            <motion.div animate={animationRight} className="col-span-8 md:col-start-7 md:col-span-12 col-start-12 row-start-1 p-4 text-left"> 
              <p className="text-white text-2xl md:text-lg font-text">NFT launch, upon sellout 40% of mint proceeds as well as 50% of secondary royalties will be allocated to the Community fund</p>
            </motion.div>
            <motion.div animate={animationLeft} className="col-span-8 md:col-start-7 md:col-span-12 col-start-3 p-4 row-start-2 md:text-left text-right"> 
              <p className="text-white text-2xl md:text-lg font-text">Immediately following sellout &amp; reveal, our first community challenge will be made available for all Simps &amp; Chads to participate</p>
            </motion.div>
            <motion.div animate={animationRight} className="col-span-8 md:col-start-7 md:col-span-12 col-start-12 p-4 row-start-3 text-left">
              <p className="text-white text-2xl md:text-lg font-text">Merch, Merch, Merch – nothing but the finest stickers and fanny packs (only kidding!). No boring shit, we talkin&#39; sneakers, ball gags, cock socks, anal beads, jewellery. Each Tribe will have their own unique items. A Unique item will be used as your membership pass to our IRL events</p>
            </motion.div>
            <motion.div animate={animationLeft} className="col-span-8 md:col-start-7 md:col-span-12 col-start-3 p-4 row-start-4 md:text-left text-right"> 
              {/* <p className="text-full-white text-2xl md:text-lg font-text">DAO launch and Community wallet (royalties 5% divided in 1% funding team 2% community wallet 2% GOLB project and 1% to holders)<br/>Charity donations decided by the community<br/> Buy land on the metaverse to enhance project networking</p> */}
              <p className="text-white text-2xl md:text-lg font-text">IRL Challenges - Lets bring the battle into the real world. Pitting Simps V Chads in real challenges for project altering consequences</p>
              </motion.div>
            <motion.div animate={animationRight} className="col-span-8 md:col-start-7 md:col-span-12 col-start-12 p-4 row-start-5 text-left"> 
              <p className="text-white text-2xl md:text-lg font-text">Companion Drop - Top secret, highly confidential.</p>
            </motion.div>
            <motion.div animate={animationLeft} className="col-span-8 md:col-start-7 md:col-span-12 col-start-3 p-4 row-start-6 md:text-left text-right"> 
              <p className="text-white text-2xl md:text-lg font-text">The ultimate Love story will be turned into a comic book.
                This is the one! All that blood, sweat &amp; semen spilled over the course of our 10 community
                challenges will be turned into a comic book, A Simp version &amp; a Chad version. This will encompass
                our entire narrative, our journey to finding love immortalised in print form
              </p>
            </motion.div>
            <motion.div animate={animationRight} className="col-span-8 md:col-start-7 md:col-span-12 col-start-12 p-4 row-start-7 text-left"> 
              <p className="text-white text-2xl md:text-lg font-text">Season 2 - new characters, new challenges, new events. Sky&#39;s the limit, all we need is our community to guide us</p>
            </motion.div>
          </div>
          <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
          <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
      </div>
    );
  };
  
export default Roadmap;
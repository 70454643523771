import React from 'react';
import { Navbar } from '../../components';
import {MobileNavbar} from '../../components';

const FAQS = [
  {
    q: 'WHAT IS SIMPS V CHADS ALL ABOUT',
    a: `4996 unique characters split across two tribes fighting for the affections of one lucky
    Temptress. Our story pits Simps Vs Chads in a narrative arc encompassing the many
    stages of courtship. Get ready to play your part in the GREATEST LOVE STORY EVER
    TOLD`
  },
  {
    q: 'HOW MANY ARE THERE',
    a: 'Our collection consists of: 2498 SIMPS, 2498 CHADS, 4 Special Edition 1 of 1 Temptresses'
  },
  {
    q: 'WHO OWNS COMMERCIAL RIGHTS OVER THE NFTS',
    a: 'The holder has full ownership and commercial usage over their NFT'
  },
  {
    q: 'WHAT IS YOUR COMMITMENT',
    a: 'Unashamed, unadulterated fun & good times, that’s our biggest promise. But also 40% of mint proceeds + an ongoing 50% of secondary royalties going to the community chest will see money go back into our members pockets by way of challenge rewards'
  },  
  {
    q: 'HOW WILL THE CHALLENGES WORK',
    a:  `Season 1 will consist of 10 virtual challenges + 3 IRL challenges. The virtual challenges will be
      spaced roughly 2-3weeks apart. The process is simple, your Simp or Chad will act as your
      membership pass to access various gated discord channels. Our challenges are essentially a
      storyline narrative encompassing the various stages of courtship. It’s a story broken up over
      10parts, each part ending with a question, one that you will have to answer but take your
      time and be wary as you only get 1 attempt at wooing your chosen temptress. The most
      creative answers will then go to a panel of judges (special guest judges from the NFT
      community) who will act as our honorary temptresses and choose the winners. The winners
      get to strut around with that Big D energy but more importantly they will also be rewarded
      with ETH prizes from our community chest.
      The IRL challenges will take a different format, a panel of Simps & Chads chosen by the
      holders will be charged with choosing a team to face off against the other side in real life
      challenges – exactly what these challenges will be are a close kept secret but rest assured we
      have big plans. The winning tribe will be gifted with powers that have PROJECT ALTERING
      consequences`
  },
  {
    q: 'WHEN WILL CHALLENGES BE READY',
    a: `The beauty of our project is that’s there’s no waiting months for a game to be built or token
    to be released. We aim to drop our first challenge immediately after sellout & reveal. Fun
    from the outset`
  },
  {
    q: 'HOW DO I BUY? WHEN MINT & HOW MUCH',
    a: `You will be able to mint directly from our website. Mint date, price & time TBA
    Post sellout, you can purchase from the major marketplaces but please consider Looksrare
    first – fostering competition in this space is vital and we would like to do our part in
    promoting alternatives
    Join our discord for updates on our launch`
  },
  {
    q: 'ANY PRESALE ACCESS',
    a: `We are still discussing plans internally – torn between a public launch with optimised gas,
    Dutch auction or an allowlist to avoid bots. We want to do right by our members but also
    don’t want any sort of whitelist grinding mechanism. We will discuss with our community
    and update further as we progress along our road to launch`
  },
  {
    q: 'ANY OTHER QUESTIONS',
    a: 'Please join our discord and speak to one of our friendly mods if you have any questions or queries'
  },

]

const renderFaq = ({q='', a=''}, idx = 0) => <div className="accordion-item bg-dark-bg border border-red-200">
  <h2 className="accordion-header font-title mb-0 " id={`heading${idx}`}><button className="accordion-button relative flex items-center first-letter:w-full w-full py-4 px-5 text-xl text-full-white text-left bg-dark-bg border-0 rounded-none transition focus:outline-none collapsed bg-black" type="collapsed" data-bs-toggle="collapse" data-bs-target={`#collapse${idx}`} aria-expanded="false" aria-controls={`collapse${idx}`}>{q}</button></h2>
  <div
    id={`collapse${idx}`}
    className="accordion-collapse collapse bg-black"
    aria-labelledby={`heading${idx}`}
    data-bs-parent="#accordionExample"
  >
    <div className="accordion-body font-text text-full-white text-lg py-4 px-5">
        {a}
    </div>
  </div>
</div>


const Faq = () => {
    return (
      <div className="grow w-full flex flex-col h-full">
        <Navbar className="sticky top-0"/>
        <MobileNavbar/>
        <h3 className='text-6xl text-white mt-16 font-title'>FAQ</h3>
        <div className="accordion w-[75%] pb-14 mx-auto my-auto" id="accordionExample">
          {FAQS.map((x,idx)=>renderFaq(x,idx))}
        </div>
        <div className="max-w-2xl justify-center mt-auto p-4 mx-auto">
          <div className='flex flex-row w-full'>
              <a href="http://discord.gg/dZz3BsPhVT" className="text-white font-text mr-6">Discord</a>
              <a href="/legal" className="text-white font-text mr-6">Terms & Conditions</a>
              <a href="https://twitter.com/SimpsVsChads" className="text-white font-text">Twitter</a>
            </div>
            <p className="text-white p-2 font-text">© 2022 SVC. All rights reserved.</p>
          </div>
      </div>
    );
  };
  
export default Faq;
import { Header, Lore, About, Roadmap, Team, Faq } from '../containers';
import { Navbar, Footer, MobileNavbar } from '../components';
import AboutPage from './About';

const Home = () => {
    return(
    <div className="w-screen text-center select-none">
        <Header />
    </div>
    )};
  
  export default Home;
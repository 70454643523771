import { Legal } from '../containers';
import { Navbar, Footer, MobileNavbar } from '../components';
import chads from '../assets/wantedchads.png'
import simps from '../assets/wantedSIMPS.png'

const WantedPage = () => {
    return(
        <div className="bg-white w-screen h-auto bg-no-repeat bg-cover">
            <div className='w-full fixed top-0 left-[37%] mt-8 h-auto max-w-2xl xl:max-w-xl md:max-w-md lg:max-w-lg mx-auto flex flex-col'>
                <Navbar />
                <MobileNavbar />
            </div>
            {/* <div className='max-w-2xl xl:max-w-xl md:max-w-md lg:max-w-lg mx-auto flex flex-col h-auto'>
                <div className='w-full mt-8 h-auto'>
                    <Navbar />
                    <MobileNavbar />
                </div>
{/*                 
                <div className='w-full h-full flex'>
                    <div className='bg-white w-auto h-auto my-auto border-4 border-[#df8600] rounded-2xl'>
                       
                    </div>
                </div>
                
            </div> */}
            <div className='bg-chadsbg bg-center bg-no-repeat bg-cover h-screen w-full flex'>
                <img className='text-black font-bold text-2xl font-text my-auto mt-36 mx-auto bg-white h-[85%] border-4 border-[#df8600] rounded-2xl' src={chads} />
            </div>
            <div className='bg-simpsbg bg-center bg-no-repeat bg-cover h-screen w-full flex'>
                <img className='text-black font-bold text-2xl font-text my-auto mt-36 mx-auto bg-white h-[85%] border-4 border-[#df8600] rounded-2xl' src={simps} />
            </div>
            
        </div>
    )};
  
  export default WantedPage;
import React from 'react';
import { LegalContent } from '../../components';

const Legal = () => {
    return (
      <div className="flex flex-col bg-no-repeat bg-right-bottom bg-cover pb-6 items-center align-middle justify-center min-h-screen mx-auto w-full" id="lore">
        <h3 className="text-full-white z-40 top-14 font-title p-4 w-full text-7xl mb-10">Legal Terms</h3>
        <LegalContent title="1)	WHAT YOU OWN" text="WHEN YOU PURCHASE OUR NFT, YOU OWN THE UNDERLYING SIMP OR CHAD ARTWORK ENTIRELY. OWNERSHIP OF THE NFT IS MEDIATED ENTIRELY BY THE SMART CONTRACT AND THE ETHEREUM NETWORK. AT NO POINT MAY WE SEIZE, FREEZE OR ALTER THE OWNERSHIP OF ANY SVC NFT YOU WILL OWN FULL COMMERCIAL RIGHTS TO YOUR SIMP OR CHAD FOR AS LONG AS YOU CONTINUE TO OWN AND CONTROL THE NFT. YOU MAY LICENSE THESE RIGHTS TO OTHER COMMERCIAL PROJECTS, HOWEVER ANY FUTURE TRANSFER OF YOUR NFT WILL BE SUBJECT TO THOSE RIGHTS YOU HAVE ASSIGNED. " />
        <LegalContent title="2)	WHAT WE OWN" text="INTELLECTUAL PROPERTY RIGHTS SURROUNDING THE “SIMPSVCHADS” AND “SVC” NAMES, LOGOS, TRADEMARKS, WEBSITE AND SMART CONTRACT CODE BELONG TO THE TEAM AS WELL AS OWNERSHIP AND CONTROL OF COMMUNITY WALLET FUNDS" />
        <LegalContent title="3)	LIMITATIONS OF LIABILITY" text="WE HAVE WORKED DILIGENTLY TO ENSURE A SMOOTH MINTING PROCESS, HOWEVER DUE TO THE NATURE AND DIFFICULTY OF SMART CONTRACTS, SOMETIMES THINGS GO WRONG. YOU AGREE TO NOT HOLD THE SVC TEAM LIABLE FOR ANY LOSSES ASSOCIATED WITH THE MINTING PROCESS. THESE INCLUDE, GAS FEES FOR FAILED TRANSACTIONS, EXCESSIVE GAS FEES OR LOSS OF THE NFT DUE TO WEBSITE OR SMART CONTRACT BUGS" />
        <LegalContent title="4)	TAXES" text="YOU ARE ENTIRELY RESPONSIBLE FOR ANY TAX OBLIGATIONS WHICH MAY ARISE FROM MINTING OR RESELLING YOUR NFT" />
        <LegalContent title="5)	CHILDREN" text="SIMPSVCHADS IS NOT A PROJECT TARGETED AT CHILDREN. BY MINTING YOU AGREE YOU ARE OVER THE AGE OF 18 OR ABOVE THE LEGAL AGE OF YOUR JURISDICTION" />
        <LegalContent title="6)	JURISDICTION" text="YOU AGREE THAT FOR ANY LEGAL DISPUTES, YOU WILL BE BOUND BY THE JURISDICTION OF AUSTRALIA AND THAT ANY LEGAL PROCEEDING WILL BE BROUGHT IN AUSTRALIA" />
        <LegalContent title="7)	PRIVACY POLICY" text="SVC WILL NOT BE COLLECTING ANY USER DATA FROM USE OF THIS WEBSITE. YOU UNDERSTAND HOWEVER, THAT THE ETHEREUM BLOCKCHAIN IS PUBLIC BLOCKCHAIN. WE ALSO USE THIRD PARTY APPS LIKE DISCORD AND COLLAB LAND THAT ARE SUBJECT TO THEIR OWN T/C’S. IN FUTURE THIS PROVISION IS LIKELY TO CHANGE WHEN WE EMBARK ON OUR ROAD MAP INITIATIVES LIKE THE MERCH STORE WHICH WILL REQUIRE US TO COLLECT AND STORE USER DATA" />
        <LegalContent title="8)	THANK YOU" text="THE ENTIRE SVC TEAM WOULD LIKE TO THANK FOR YOUR CONTINUED SUPPORT. ANY QUESTIONS OR CONCERNS ABOUT ANY OF THESE TERMS, PLEASE JOIN OUR DISCORD AND SPEAK TO ONE OF OUR FRIENDLY MODS" />
      </div>
    );
  };
  
export default Legal;